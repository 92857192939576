import React, { useEffect } from 'react';
import { func, number, string } from 'prop-types';
import moment from 'moment';
import { useDidMount } from '../../../hooks/useDidMount';
import { useCartState } from '../../../contexts/cart';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useFilterState } from '../../cap';
import useCartEstimate from '../../App/hooks/useCartEstimate';
import EditHowToGetOrder from './editHowToGetYourOrder/EditHowToGetOrder';
import ReadOnlyHowToGetOrder from './ReadOnlyHowToGetOrder';
import CollapsedHowToGetOrder from './CollapsedHowToGetOrder';
import { areBothSlotsSelected } from '../utils/checkoutUtils';
import { logError } from '../../global/utils/logger';
import './howToGetOrder.scss';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

const HowToGetOrder = props => {
    const [{ cart, howToGetYourOrderDetails }] = useCartState();
    const [{ viewCart, startDate, endDate }, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const { currentStep, handleStepChange, currentOffSet, formStep, isCustomAccount } = props;
    const stepNumber = formStep.HTGO;
    const didMount = useDidMount();
    const userType = useCheckUser();
    const [{ getEstimates }] = useCartEstimate();
    const isCreditRoundTrip = userType === USER_TYPE.CREDIT && !viewCart?.isInStorePickup;
    useEffect(() => {
         function deliveryTimeEstimatesUpdate() {
            try {
                if (didMount && currentStep >= stepNumber && (areBothSlotsSelected(howToGetYourOrderDetails) || isCreditRoundTrip)) {
                     getEstimates('', 0, '', '', true, cart?.availableCartItems);
                    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ESTIMATES_LOADING);
                }
            } catch (err) {
                logError(err, false, 'deliveryTimeEstimatesUpdate');
            }
        }
        deliveryTimeEstimatesUpdate();
    }, [
        howToGetYourOrderDetails?.selectedPickupTime,
        howToGetYourOrderDetails?.selectedReturnTime,
        howToGetYourOrderDetails?.selectedPickUpTime?.label,
        howToGetYourOrderDetails?.selectedDeliveryTime?.label,
        didMount
    ]);



    return (
        <div className={'howToGetOrder'}>
            {currentStep < stepNumber && <CollapsedHowToGetOrder formStep={formStep} />}
            {currentStep === stepNumber && (
                <EditHowToGetOrder
                    startDate={startDate}
                    endDate={endDate}
                    currentStep={currentStep}
                    currentOffSet={currentOffSet}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyHowToGetOrder
                    handleStepChange={handleStepChange}
                    startDate={moment(startDate).format('MMM DD, YYYY')}
                    endDate={moment(endDate).format('MMM DD, YYYY')}
                    selectedStartTime={howToGetYourOrderDetails.selectedPickupTime}
                    selectedEndTime={howToGetYourOrderDetails.selectedReturnTime}
                    isInstore={isInStorePickup}
                    storeDetails={howToGetYourOrderDetails.selectedStoreDetails}
                    deliveryTime={howToGetYourOrderDetails.selectedDeliveryTime.label}
                    pickupTime={howToGetYourOrderDetails.selectedPickUpTime.label}
                    formStep={formStep}
                />
            )}
        </div>
    );
};

export default HowToGetOrder;

HowToGetOrder.propsType = {
    currentStep: number,
    handleStepChange: func,
    currentOffSet: string
};

HowToGetOrder.defaultProps = {
    currentStep: 0,
    handleStepChange: () => {},
    currentOffSet: ''
};
