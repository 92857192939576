import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import InfoIcon from '../../../../../resources/images/info.svg';
import DownChev from '../../../../../resources/images/arrow-down.svg';
import CartIcon from '../../../../../resources/images/shopping-cart.svg'
import './orderSummaryShimmer.scss';
import { FACTORYSOURCE, SHOW_FIELDS } from '../../constants/orderSummaryFactoryConstants';
import { string } from 'prop-types';

const OrderSummaryShimmer = ({variant}) => {
    const intl = useIntl();

    const renderOrderSummaryItemShimmer = (itemLabel, showTooltip = false, ) => (
        <div className="order-summary-shimmer-item">
            <span className="order-summary-shimmer-item-key">
                {itemLabel}
                {showTooltip && (
                    <button className="toolTipIcon" aria-hidden="true" disabled>
                        <InfoIcon aria-hidden="true" />
                    </button>
                )}
            </span>
            <span className="order-summary-shimmer-item-value">
                <div className="order-summary-item-shimmer shimmer-cart"></div>
            </span>
        </div>
    );

    const getVariantSpecificData = useMemo(() => {
        const getData = (type) => {
            const renderHeader = () => {
                return variant === FACTORYSOURCE.CHECKOUT ? (
                    <>
                        <h4 className="block-checkout-title">{intl.formatMessage({ id: 'cart-order-summary:title' })}</h4>
                        <div className="accordion__item">
                            <div className="accordion__title">
                                <div className="accordian_heading">
                                    <CartIcon className="cartIconStyle" aria-hidden="true" />
                                    <div className="order-summary-shimmer-item-count shimmer-cart"></div>
                                </div>
                                <div className="expand_button">
                                    <DownChev className="chevronIconStyle" aria-hidden="true" />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="order-summary-shimmer-top">
                        <h5 className="order-summary-shimmer-top-title">{intl.formatMessage({ id: 'cart-order-summary:title' })}</h5>
                    </div>
                );
            };

            const renderTaxes = () => {
                return renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:subtotal' }));
            };

            const renderFooter = () => {
                return (
                    <div className="ordersummary-total">
                        {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:total' }))}
                    </div>
                );
            };

            switch (variant) {
                case FACTORYSOURCE.CHECKOUT:
                    if (type === SHOW_FIELDS.HEADER) return renderHeader();
                    if (type === SHOW_FIELDS.TAXES) return renderTaxes();
                    if (type === SHOW_FIELDS.FOOTER) return renderFooter();
                    break;
                case FACTORYSOURCE.CART:
                    if (type === SHOW_FIELDS.HEADER) return renderHeader();
                    break;
                default:
                    return null;
            }
        };

        return getData;
    }, [variant, intl]);


    return (
        <section className="order-summary-shimmer">
            {getVariantSpecificData(SHOW_FIELDS.HEADER)}
            <div className={`items group ${variant === FACTORYSOURCE.CHECKOUT ? 'ordersummary':''}`}>
                {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:rental-subtotal' }))}
                {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:purchases-subtotal' }))}
                {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:instore-pickup' }), true)}
                {renderOrderSummaryItemShimmer(
                        intl.formatMessage({ id: 'order-summary:rental-protection-plan' }),
                        true
                )}
                {renderOrderSummaryItemShimmer(
                        intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' }),
                        true
                )}
                {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:environment-fee' }), true)}
                {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:other-fee' }), true)}
                <div className="order-summary-shimmer-item order-summary-item-taxes">
                    {getVariantSpecificData(SHOW_FIELDS.TAXES)}
                    {renderOrderSummaryItemShimmer(intl.formatMessage({ id: 'order-summary:taxes' }))}
                </div>
                {getVariantSpecificData(SHOW_FIELDS.FOOTER)}
            </div>
        </section>
    );
};

export default memo(OrderSummaryShimmer);

OrderSummaryShimmer.propTypes = {
    variant: string
};
OrderSummaryShimmer.defaultProps = {
    variant: FACTORYSOURCE.CART
};